import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./pages/Home";
import DigitalMarketing from "./pages/Digital-marketing";
import PaymentProcessing from "./pages/Payment-processing";
import HRManagement from "./pages/HR-Management";
import Startup from "./pages/Startup";
import HomeCRM from "./pages/Home-CRM";
import About from "./pages/About";
import Service from "./pages/Service";
import Process from "./pages/Process";
import Team from "./pages/Team";
import Portfolio2col from "./pages/Portfolio-2col";
import Portfolio3col from "./pages/Portfolio-3col";
import Portfoliofull4col from "./pages/Portfolio-fullwidth-4col";
import PortfolioSingle from "./pages/PortfolioSingle";
import Bloglist from "./pages/Bloglist";
import BlogSingle from "./pages/BlogSingle";
import Contact from "./pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Landing from "./pages/Landing";
import homesupport from "./pages/home-support";
import homeERP from "./pages/Home-ERP";
import homeHosting from "./pages/HomeHosting";
import homeSecurity from "./pages/HomeSecurity";
import homeSoftwareDark from "./pages/Home-software-dark";
import HomeAppShowcase from "./pages/Home-app-showcase";
import HomeCloud from "./pages/Home-cloud";
import HomeTracking from "./pages/Home-tracking";
import HomeEvent from "./pages/Home-event";
import HomeChat from "./pages/Home-chat";
import Price from "./pages/Price";
import Faq from "./pages/Faq";
import ServiceDetails from "./pages/ServiceDetails";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import BlogGridPage from "./pages/BlogGridPage";
import NotFound from "./pages/404";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute
            path="/Digital-marketing"
            component={DigitalMarketing}
          />
          <ScrollToTopRoute
            path="/Payment-processing"
            component={PaymentProcessing}
          />
          <ScrollToTopRoute path="/HR-Management" component={HRManagement} />
          <ScrollToTopRoute path="/Startup" component={Startup} />
          <ScrollToTopRoute path="/Home-CRM" component={HomeCRM} />
          <ScrollToTopRoute path="/About" component={About} />
          <ScrollToTopRoute path="/Service" component={Service} />
          <ScrollToTopRoute path="/Process" component={Process} />
          <ScrollToTopRoute path="/Team" component={Team} />
          <ScrollToTopRoute path="/Portfolio-2col" component={Portfolio2col} />
          <ScrollToTopRoute path="/Portfolio-3col" component={Portfolio3col} />
          <ScrollToTopRoute
            path="/Portfolio-fullwidth-4col"
            component={Portfoliofull4col}
          />
          <ScrollToTopRoute
            path="/PortfolioSingle"
            component={PortfolioSingle}
          />
          <ScrollToTopRoute path="/Bloglist" component={Bloglist} />
          <ScrollToTopRoute path="/BlogSingle" component={BlogSingle} />
          <ScrollToTopRoute path="/BlogGridPage" component={BlogGridPage} />
          <ScrollToTopRoute path="/Contact" component={Contact} />
          <ScrollToTopRoute path="/Landing" component={Landing} />
          <ScrollToTopRoute path="/home-support" component={homesupport} />
          <ScrollToTopRoute path="/HomeHosting" component={homeHosting} />
          <ScrollToTopRoute path="/Home-ERP" component={homeERP} />
          <ScrollToTopRoute path="/HomeSecurity" component={homeSecurity} />
          <ScrollToTopRoute
            path="/HomeAppShowcase"
            component={HomeAppShowcase}
          />
          <ScrollToTopRoute
            path="/Home-software-dark"
            component={homeSoftwareDark}
          />
          <ScrollToTopRoute path="/Home-cloud" component={HomeCloud} />
          <ScrollToTopRoute path="/Home-Tracking" component={HomeTracking} />
          <ScrollToTopRoute path="/Home-event" component={HomeEvent} />
          <ScrollToTopRoute path="/Home-chat" component={HomeChat} />
          <ScrollToTopRoute path="/Price" component={Price} />
          <ScrollToTopRoute path="/Faq" component={Faq} />
          <ScrollToTopRoute path="/ServiceDetails" component={ServiceDetails} />
          <ScrollToTopRoute path="/SignIn" component={SignIn} />
          <ScrollToTopRoute path="/SignUp" component={SignUp} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
